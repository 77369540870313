import { variables } from '$lib/config';
import '$lib/polyfills/hasOwn';
import { user as userStore } from '$lib/stores/auth';
import * as Sentry from '@sentry/sveltekit';
import { get } from 'svelte/store';

// workaround until https://github.com/sveltejs/kit/issues/10169 is closed
Sentry.init({
	enabled: variables.SENTRY_ENABLED,
	dsn: variables.SENTRY_DSN,
	environment: variables.SENTRY_ENVIRONMENT,
	tracesSampleRate: variables.SENTRY_TRACES_SAMPLE_RATE,
	integrations: [],
	beforeSend(event) {
		const user = get(userStore);

		if (user) {
			event.user = {
				email: user.email ?? null,
				id: user.id ?? null,
				username: user.first_name && user.last_name ? user.first_name + ' ' + user.last_name : null
			};

			event.contexts = {
				team: {
					name: user.team?.name ?? null,
					id: user.team?.id ?? null
				}
			};
		}

		return event;
	}
});

/*
see: https://github.com/sveltejs/kit/issues/10169

Sentry.init({
	enabled: variables.SENTRY_ENABLED,
	dsn: variables.SENTRY_DSN,
	environment: variables.SENTRY_ENVIRONMENT,
	tracesSampleRate: variables.SENTRY_TRACES_SAMPLE_RATE,
	integrations: []
});

export const handleError = (async () => {
	if (variables.SENTRY_ENABLED) {
		const user = get(userStore);
		if (user) {
			Sentry.setUser({
				email: user.email ?? null,
				id: user.id ?? null,
				username: user.first_name && user.last_name ? user.first_name + ' ' + user.last_name : null
			});

			Sentry.setContext('team', {
				name: user.team?.name ?? null,
				id: user.team?.id ?? null
			});
		}

		handleErrorWithSentry();
	}

	return {
		message: `An error occurred. Please contact support.`
	};
}) satisfies HandleClientError;
 */
