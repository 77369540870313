import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146')
];

export const server_loads = [2,4,8,9,23,11,13,15,17,18,20,24];

export const dictionary = {
		"/": [~25],
		"/(app)/analytics/carbon-report": [~26,[2],[3]],
		"/(app)/analytics/cost-report": [~27,[2],[3]],
		"/(app)/analytics/reporting": [~28,[2],[3]],
		"/(app)/analytics/time-records": [~29,[2],[3]],
		"/(app)/analytics/utilization": [~30,[2],[3]],
		"/(app)/analytics/vacation-overview/[[year=yearPlusOne]]": [~31,[2],[3]],
		"/(app)/clients": [~32,[2],[3]],
		"/(app)/clients/create": [~42,[2],[3]],
		"/(app)/clients/settings": [~43,[2],[3]],
		"/(app)/clients/[clientId=uuid]": [~33,[2,4],[3]],
		"/(app)/clients/[clientId=uuid]/address/create": [~35,[2,5],[3]],
		"/(app)/clients/[clientId=uuid]/address/[addressId=uuid]/edit": [~34,[2,5],[3]],
		"/(app)/clients/[clientId=uuid]/contacts/create": [~37,[2,6],[3]],
		"/(app)/clients/[clientId=uuid]/contacts/[contactId=uuid]/edit": [~36,[2,6],[3]],
		"/(app)/clients/[clientId=uuid]/edit": [~38,[2,7],[3]],
		"/(app)/clients/[clientId=uuid]/overview": [~39,[2,4],[3]],
		"/(app)/clients/[clientId=uuid]/prices": [~40,[2,4],[3]],
		"/(app)/clients/[clientId=uuid]/projects": [~41,[2,4],[3]],
		"/(app)/dashboard": [~44,[2],[3]],
		"/(app)/groups": [~45,[2],[3]],
		"/(app)/groups/settings": [~50,[2],[3]],
		"/(app)/groups/[groupId=uuid]": [~46,[2,8],[3]],
		"/(app)/groups/[groupId=uuid]/bookings": [47,[2,8],[3]],
		"/(app)/groups/[groupId=uuid]/items": [48,[2,8],[3]],
		"/(app)/groups/[groupId=uuid]/projects": [~49,[2,8],[3]],
		"/(app)/inventory-items": [~51,[2],[3]],
		"/(app)/inventory-items/create": [~56,[2],[3]],
		"/(app)/inventory-items/settings": [~57,[2],[3]],
		"/(app)/inventory-items/[itemId=uuid]/client-prices": [~52,[2,9],[3]],
		"/(app)/inventory-items/[itemId=uuid]/components": [~53,[2,9],[3]],
		"/(app)/inventory-items/[itemId=uuid]/edit": [~54,[2,10],[3]],
		"/(app)/inventory-items/[itemId=uuid]/overview": [55,[2,9],[3]],
		"/(app)/invoicing/invoices/create": [~63,[2],[3]],
		"/(app)/invoicing/invoices/settings": [~64,[2],[3]],
		"/(app)/invoicing/invoices/[invoiceId=uuid]/edit": [~59,[2],[3]],
		"/(app)/invoicing/invoices/[invoiceId=uuid]/finalize": [~60,[2],[3]],
		"/(app)/invoicing/invoices/[invoiceId=uuid]/send": [~61,[2],[3]],
		"/(app)/invoicing/invoices/[invoiceId=uuid]/view": [~62,[2],[3]],
		"/(app)/invoicing/invoices/[...statusFilter=invoicingInvoiceStatus]": [~58,[2],[3]],
		"/(app)/invoicing/recurring/create": [~68,[2],[3]],
		"/(app)/invoicing/recurring/[invoiceId=uuid]/edit": [~66,[2],[3]],
		"/(app)/invoicing/recurring/[invoiceId=uuid]/schedule": [~67,[2],[3]],
		"/(app)/invoicing/recurring/[...statusFilter=invoicingRecurringStatus]": [~65,[2],[3]],
		"/(auth)/login": [140,[23]],
		"/(auth)/login/[slug]": [~141,[23]],
		"/(app)/planning/overview": [~69,[2],[3]],
		"/(app)/planning/projects": [~70,[2],[3]],
		"/(app)/planning/projects/create": [~81,[2],[3]],
		"/(app)/planning/projects/settings": [~82,[2],[3]],
		"/(app)/planning/projects/[projectId=uuid]": [~71,[2,11],[3]],
		"/(app)/planning/projects/[projectId=uuid]/bookings": [72,[2,11],[3]],
		"/(app)/planning/projects/[projectId=uuid]/carbon-report": [~73,[2,11],[3]],
		"/(app)/planning/projects/[projectId=uuid]/cost-report": [~74,[2,11],[3]],
		"/(app)/planning/projects/[projectId=uuid]/edit": [~75,[2,12],[3]],
		"/(app)/planning/projects/[projectId=uuid]/overview": [~76,[2,11],[3]],
		"/(app)/planning/projects/[projectId=uuid]/planning": [~77,[2,11],[3]],
		"/(app)/planning/projects/[projectId=uuid]/po-numbers": [~78,[2,11],[3]],
		"/(app)/planning/projects/[projectId=uuid]/presences/[[year=year]]/[[month=month]]": [~79,[2,11],[3]],
		"/(app)/planning/projects/[projectId=uuid]/report": [~80,[2,11],[3]],
		"/(auth)/register": [142,[23]],
		"/(auth)/register/complete/[email]": [143,[23]],
		"/(auth)/reset": [144,[23]],
		"/(auth)/reset/[email]/[token]": [145,[23]],
		"/(app)/resources": [~83,[2],[3]],
		"/(app)/resources/create": [~89,[2],[3]],
		"/(app)/resources/settings": [~90,[2],[3]],
		"/(app)/resources/[resourceId=uuid]": [~84,[2,13],[3]],
		"/(app)/resources/[resourceId=uuid]/bookings": [85,[2,13],[3]],
		"/(app)/resources/[resourceId=uuid]/edit": [~86,[2,14],[3]],
		"/(app)/resources/[resourceId=uuid]/overview": [87,[2,13],[3]],
		"/(app)/resources/[resourceId=uuid]/projects": [~88,[2,13],[3]],
		"/(app)/sites": [~91,[2],[3]],
		"/(app)/sites/create": [~99,[2],[3]],
		"/(app)/sites/settings": [~100,[2],[3]],
		"/(app)/sites/[siteId=uuid]": [~92,[2],[3]],
		"/(app)/sites/[siteId=uuid]/edit": [~93,[2],[3]],
		"/(app)/sites/[siteId=uuid]/suites/create": [~98,[2],[3]],
		"/(app)/sites/[siteId=uuid]/suites/[suiteId=uuid]": [~94,[2,15],[3]],
		"/(app)/sites/[siteId=uuid]/suites/[suiteId=uuid]/bookings": [95,[2,15],[3]],
		"/(app)/sites/[siteId=uuid]/suites/[suiteId=uuid]/edit": [~96,[2,16],[3]],
		"/(app)/sites/[siteId=uuid]/suites/[suiteId=uuid]/overview": [97,[2,15],[3]],
		"/(app)/staffs": [~101,[2],[3]],
		"/(app)/staffs/settings": [~110,[2],[3]],
		"/(app)/staffs/[staffId=uuid]": [~102,[2,17],[3]],
		"/(app)/staffs/[staffId=uuid]/bookings": [103,[2,17],[3]],
		"/(app)/staffs/[staffId=uuid]/contact": [~104,[2,17],[3]],
		"/(app)/staffs/[staffId=uuid]/presences": [~105,[2,17],[3]],
		"/(app)/staffs/[staffId=uuid]/presences/[year=year]": [~106,[2,17],[3]],
		"/(app)/staffs/[staffId=uuid]/presences/[year=year]/[month=month]": [~107,[2,17],[3]],
		"/(app)/staffs/[staffId=uuid]/projects": [~108,[2,17],[3]],
		"/(app)/staffs/[staffId=uuid]/working-hours": [~109,[2,17],[3]],
		"/(app)/team/[teamId=uuid]/activity": [~111,[2,18],[3]],
		"/(app)/team/[teamId=uuid]/administration": [~112,[2,18],[3]],
		"/(app)/team/[teamId=uuid]/integrations": [~113,[2,18,19],[3]],
		"/(app)/team/[teamId=uuid]/integrations/accesstokens": [~114,[2,18,19],[3]],
		"/(app)/team/[teamId=uuid]/integrations/overview": [~115,[2,18,19],[3]],
		"/(app)/team/[teamId=uuid]/integrations/screens": [~116,[2,18,19],[3]],
		"/(app)/team/[teamId=uuid]/integrations/screens/create": [~118,[2,18,19],[3]],
		"/(app)/team/[teamId=uuid]/integrations/screens/[screenId=uuid]": [~117,[2,18,19],[3]],
		"/(app)/team/[teamId=uuid]/integrations/webhooks": [~119,[2,18,19],[3]],
		"/(app)/team/[teamId=uuid]/members": [~120,[2,18],[3]],
		"/(app)/team/[teamId=uuid]/privacy": [~121,[2,18],[3]],
		"/(app)/team/[teamId=uuid]/settings": [~122,[2,18,20],[3]],
		"/(app)/team/[teamId=uuid]/settings/bookings": [~123,[2,18,20],[3]],
		"/(app)/team/[teamId=uuid]/settings/companies": [~124,[2,18,20],[3]],
		"/(app)/team/[teamId=uuid]/settings/customization": [~125,[2,18,20],[3]],
		"/(app)/team/[teamId=uuid]/settings/general": [~126,[2,18,20],[3]],
		"/(app)/team/[teamId=uuid]/settings/invoicing": [~127,[2,18,20],[3]],
		"/(app)/team/[teamId=uuid]/settings/regional": [~128,[2,18,20],[3]],
		"/(app)/team/[teamId=uuid]/settings/time-tracking": [~129,[2,18,20],[3]],
		"/(app)/time-tracking": [~130,[2,21],[3]],
		"/(app)/time-tracking/absence/create": [~131,[2,21],[3]],
		"/(app)/time-tracking/overview": [~132,[2,21],[3]],
		"/(app)/time-tracking/overview/[year=yearPlusOne]": [~133,[2,21],[3]],
		"/(app)/time-tracking/overview/[year=yearPlusOne]/[month=month]": [~134,[2,21],[3]],
		"/(app)/time-tracking/presence/create": [~135,[2,21],[3]],
		"/(app)/time-tracking/vacation/create": [~136,[2,21],[3]],
		"/(app)/user/account": [~137,[2,22],[3]],
		"/(app)/user/profile": [~138,[2,22],[3]],
		"/(app)/user/settings": [~139,[2,22],[3]],
		"/(wizard)/wizard": [~146,[24]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';