import type { ParamMatcher } from '@sveltejs/kit';

export const match: ParamMatcher = (param) => {
	const month = parseInt(param);
	// We check for month < 0 because we want to allow 0 as a valid month, for "no month selected"
	if (isNaN(month) || month < 0 || month > 12) {
		return false;
	}
	return true;
};
