if (typeof Object.hasOwn !== 'function') {
	console.log('Polyfill Object.prototype.hasOwn');

	Object.defineProperty(Object.prototype, 'hasOwn', {
		value: function (object: object, property: string | symbol): boolean {
			if (this == null) {
				throw new TypeError('Cannot convert undefined or null to object');
			}
			return Object.prototype.hasOwnProperty.call(object, property);
		},
		configurable: true,
		enumerable: false,
		writable: true
	});
}
